<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="clearfix">
          <h1 class="float-left display-3 mr-4">
            404
          </h1>
          <h4 class="pt-3">
            ¡Vaya! Estás perdido.
          </h4>
          <p class="text-muted">
            La página que estás buscando, no ha sido encontrada.
          </p>
          <CButton 
            class="btn btn-info"
            @click="click" 
          >
            Inicio
          </CButton>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { checkUser } from "@/utils/token";

export default {
  name: "Page404",
  created(){
    if(!checkUser().status){
      this.$router.push('/login')
    }
  },
  methods:{
    /**
     * Función para poder ir a la Home
     */
    click(){
      this.$router.push("/")
    }
  }
};
</script>
